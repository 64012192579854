<template>
  <v-toolbar>
    <v-toolbar-title class="title_class">{{ title }}</v-toolbar-title>
    <v-spacer></v-spacer>
    <v-toolbar-items>
      <v-btn
        icon
        @click="$emit('download')"
      >
        <v-icon>mdi-download</v-icon>
      </v-btn>
      <v-btn
        icon
        @click="$emit('zoomOut')"
      >
        <v-icon>mdi-magnify-minus-outline</v-icon>
      </v-btn>

      <v-btn
        icon
        @click="$emit('zoomIn')"
      >
        <v-icon>mdi-magnify-plus-outline</v-icon>
      </v-btn>
    </v-toolbar-items>
    <template v-slot:extension>
      <v-tabs
        color="#b31616"
        v-model="selectedTab"
        align-with-title
      >
        <v-tabs-slider color="#b31616"></v-tabs-slider>
        <v-tab
          v-for="(tab, index) in tabs"
          :key="index"
        >
          {{ tab.title }}
        </v-tab>
      </v-tabs>
    </template>
  </v-toolbar>
</template>

<script>
export default {
  name: "resourceHeader",
  props: ["value", "title", "tabs"],
  computed: {
    selectedTab: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  data: () => ({}),
  methods: {},
};
</script>

<style>
.title_class {
  font-size: 2.2rem;
  color: #b31616;
}
</style>